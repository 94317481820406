import React from 'react';
import { st, classes } from './Sidebar.st.css';
import { MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';

type SidebarProps = {
  width: string;
  sidebarFreezePosition: boolean;
  className?: string;
};

const SidebarComponent: React.FC<SidebarProps> = ({
  sidebarFreezePosition,
  width,
  className,
  children,
}) => {
  return (
    <RenderLocationProvider value={MainComponents.SIDEBAR}>
      <div
        style={{ flexBasis: width }}
        className={st(
          classes.root,
          { sticky: sidebarFreezePosition },
          className,
        )}
      >
        {children}
      </div>
    </RenderLocationProvider>
  );
};

export default SidebarComponent;
