import React from 'react';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { st, classes } from './BookButton.st.css';
import { useRenderLocation } from '../useRenderLocation';

interface ControllerProps {
  onClick?: Function;
  dataHook?: string;
  className?: string;
}

type BookButtonProps = InjectedTranslateProps & ControllerProps;

const BookButtonComponent: React.FC<BookButtonProps> = ({
  t,
  onClick,
  children,
  dataHook,
  className,
}) => {
  const location = useRenderLocation();
  return (
    <Button
      className={st(classes.root, { location }, className)}
      data-hook={dataHook || 'book-button'}
      size={SIZE.medium}
      priority={PRIORITY.primary}
      onClick={() => {
        //   todo go to iframe
        onClick && onClick();
      }}
      // fullWidth={isMobile}
      aria-label="Book Now"
      role="button"
      tabIndex={0}
      upgrade
    >
      {children || t('app.widget.cta.book-now')}
    </Button>
  );
};

export default translate()(BookButtonComponent);
