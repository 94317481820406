import React, { useRef } from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { st, classes } from './Header.st.css';
import { MediaImage } from 'wix-ui-core/media-image';
import { HeaderViewModel } from '../../../../service-page-view-model/header-view-model/headerViewModel';
import { translate } from 'react-i18next';
import { withExperiments } from '@wix/wix-experiments-react';
import {
  AlignmentOptions,
  ImagePositionOptions,
  MainComponents,
} from '../../types';
import BookButton from '../BookButton/BookButton';
import { RenderLocationProvider } from '../useRenderLocation';

type HeaderProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    viewModel?: HeaderViewModel;
    serviceTitleVisibility: boolean;
    imageVisibility: boolean;
    headerTitleAlignment: AlignmentOptions;
    bookButtonVisibility: boolean;
    headerImagePosition: ImagePositionOptions;
    className?: string;
  };

const Header: React.FC<HeaderProps> = ({
  viewModel,
  serviceTitleVisibility,
  imageVisibility,
  headerTitleAlignment,
  bookButtonVisibility,
  headerImagePosition,
  className,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const imagePositionToFocalPoint = {
    [ImagePositionOptions.BOTTOM]: { x: 50, y: 100 },
    [ImagePositionOptions.BOTTOM_LEFT]: { x: 0, y: 100 },
    [ImagePositionOptions.BOTTOM_RIGHT]: { x: 100, y: 100 },
    [ImagePositionOptions.LEFT]: { x: 0, y: 50 },
    [ImagePositionOptions.RIGHT]: { x: 100, y: 50 },
    [ImagePositionOptions.TOP]: { x: 50, y: 0 },
    [ImagePositionOptions.TOP_LEFT]: { x: 0, y: 0 },
    [ImagePositionOptions.TOP_RIGHT]: { x: 100, y: 0 },
    [ImagePositionOptions.MIDDLE]: { x: 50, y: 50 },
  };
  const headerDimensions = headerRef.current?.getClientRects()?.[0];

  const serviceImage = () =>
    imageVisibility && viewModel?.imageUri && headerDimensions ? (
      <div className={classes.media}>
        <MediaImage
          key={`${JSON.stringify(headerImagePosition)} ${
            headerDimensions.height
          } ${headerDimensions.width}`}
          mediaPlatformItem={{
            height: headerDimensions.height,
            uri: viewModel.imageUri,
            width: headerDimensions.width,
            options: {
              focalPoint: imagePositionToFocalPoint[headerImagePosition],
            },
          }}
          height={headerDimensions.height}
          width={headerDimensions.width}
        />
      </div>
    ) : null;

  const serviceTitle = () =>
    serviceTitleVisibility ? (
      <Text
        data-hook="header-title"
        className={classes.title}
        typography={TYPOGRAPHY.largeTitle}
        tagName="h1"
      >
        {viewModel?.title}
      </Text>
    ) : null;

  const bookButton = () => (bookButtonVisibility ? <BookButton /> : null);

  return (
    <RenderLocationProvider value={MainComponents.HEADER}>
      <div
        className={st(
          classes.root,
          { alignment: headerTitleAlignment },
          className,
        )}
        data-hook="header-wrapper"
        ref={headerRef}
      >
        {serviceImage()}
        <div className={st(classes.contentWrapper, className)}>
          {serviceTitle()}
          {bookButton()}
        </div>
      </div>
    </RenderLocationProvider>
  );
};

export default translate()(withExperiments(Header));
