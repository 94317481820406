import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { st, classes } from './TitleAndTagline.st.css';
import { AlignmentOptions, ISection, SectionTypes } from '../../types';
import Section from '../Section';
import { TitleAndTaglineViewModel } from '../../../../service-page-view-model/title-and-tagline-view-model/titleAndTaglineViewModel';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { Camera } from 'wix-ui-tpa/dist/src/assets/icons/index';
import { useRenderLocation } from '../useRenderLocation';
import { useGlobalAlignment } from '../useGlobalAlignment';

type TitleAndTaglineProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    titleAndTaglineAlignment: AlignmentOptions | undefined;
    serviceTitleVisibility: boolean;
    serviceTaglineVisibility: boolean;
    onlineBadgeVisibility: boolean;
    onlineBadgeText: string;
    viewModel?: TitleAndTaglineViewModel;
    section: ISection;
    className?: string;
  };

export default translate()(
  withExperiments<TitleAndTaglineProps>(
    ({
      t,
      experiments,
      titleAndTaglineAlignment,
      serviceTaglineVisibility,
      serviceTitleVisibility,
      onlineBadgeVisibility,
      onlineBadgeText,
      viewModel,
      className,
    }) => {
      const location = useRenderLocation();
      const serviceTitle = () =>
        serviceTitleVisibility ? (
          <Text
            data-hook="title-and-tagline-title"
            className={st(classes.title, { location }, className)}
            typography={TYPOGRAPHY.largeTitle}
            tagName="h2"
          >
            {viewModel?.title}
          </Text>
        ) : null;

      const serviceTagline = () =>
        serviceTaglineVisibility && viewModel?.tagline ? (
          <Text
            data-hook="title-and-tagline-tagline"
            className={st(classes.tagline, { location }, className)}
            typography={TYPOGRAPHY.runningText}
          >
            {viewModel.tagline}
          </Text>
        ) : null;

      const onlineBadge = () =>
        onlineBadgeVisibility && viewModel?.onlineProvider ? (
          <Badge
            icon={<Camera />}
            data-hook="title-and-tagline-badge"
            priority={BADGE_PRIORITY.light}
            className={classes.badge}
          >
            {onlineBadgeText ||
              t('app.settings.sections.titles.default.online-badge.text')}
          </Badge>
        ) : null;

      return (
        <Section
          sectionType={SectionTypes.TITLE_TAGLINE}
          childrenAlignment={titleAndTaglineAlignment}
        >
          <div
            className={st(
              classes.root,
              { alignment: titleAndTaglineAlignment || useGlobalAlignment() },
              className,
            )}
            data-hook="title-and-tagline-wrapper"
          >
            {onlineBadge()}
            {serviceTitle()}
            {serviceTagline()}
          </div>
        </Section>
      );
    },
  ),
);
