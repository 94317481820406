import { AlignmentOptions, MainComponents } from '../types';
import { useRenderLocation } from './useRenderLocation';
import { useWidgetSettings } from './useWidgetSettings';

export const useGlobalAlignment = (): AlignmentOptions => {
  const location = useRenderLocation();
  const settings = useWidgetSettings();
  if (location === MainComponents.BODY) {
    return settings.bodyAlignment;
  } else if (location === MainComponents.SIDEBAR) {
    return settings.sidebarAlignment;
  } else {
    return settings.headerTitleAlignment;
  }
};
