import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { ISection, SectionTypes } from '../../types';
import Section from '../Section';
import { ContactSectionViewModel } from '../../../../service-page-view-model/contact-section-view-model/contactSectionViewModel';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';

type ContactComponentProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    businessEmailVisibility: boolean;
    businessPhoneNumberVisibility: boolean;
    businessAddressVisibility: boolean;
    contactDetailsTitleText: string;
    viewModel?: ContactSectionViewModel;
    section: ISection;
    className?: string;
  };

export default translate()(
  withExperiments<ContactComponentProps>(
    ({
      t,
      businessEmailVisibility,
      businessPhoneNumberVisibility,
      businessAddressVisibility,
      contactDetailsTitleText,
      viewModel,
      className,
    }) => {
      const contactSectionTitle = () => {
        const contactDetailsTitle =
          contactDetailsTitleText ||
          t(
            'app.settings.sections.contact.default.contact-details-title.label',
          );
        return (
          <SectionTitle
            title={contactDetailsTitle}
            dataHook="contact-title"
            tagName="h2"
          />
        );
      };

      const businessPhoneNumber = () => {
        return businessPhoneNumberVisibility && viewModel?.phone ? (
          <div>
            <SectionText
              text={viewModel.phone}
              dataHook="business-phone-number"
            />
          </div>
        ) : null;
      };

      const businessEmail = () => {
        return businessEmailVisibility && viewModel?.email ? (
          <div>
            <SectionText text={viewModel.email} dataHook="business-email" />
          </div>
        ) : null;
      };

      const businessAddress = () => {
        return businessAddressVisibility && viewModel?.address ? (
          <div>
            <SectionText text={viewModel.address} dataHook="business-address" />
          </div>
        ) : null;
      };

      return viewModel ? (
        <Section sectionType={SectionTypes.CONTACT}>
          <div data-hook="contact-details-wrapper">
            {contactSectionTitle()}
            {businessPhoneNumber()}
            {businessEmail()}
            {businessAddress()}
          </div>
        </Section>
      ) : null;
    },
  ),
);
